window.onload = () => {
  const confettiContainer = document.getElementById('confetti-container');
  const colors = ['#ff0000', '#00ff00', '#0000ff', '#ff00ff', '#ffff00', '#00ffff'];

  // Scrollen während der Animation blockieren
  document.body.style.overflow = 'hidden';

  for (let i = 0; i < 100; i++) {
    const confetti = document.createElement('div');
    confetti.classList.add('confetti');
    confetti.style.left = `${Math.random() * 100}vw`;
    confetti.style.animationDelay = `${Math.random() * 2}s`;
    confetti.style.setProperty('--color', colors[Math.floor(Math.random() * colors.length)]);

    confettiContainer.appendChild(confetti);
  }

  setTimeout(() => {
    confettiContainer.innerHTML = ''; // Entfernt das Konfetti nach der Animation

    // Scrollen wieder aktivieren
    document.body.style.overflow = 'auto';
  }, 4000); // Timeout für die Länge der Animation
};
